import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

const EmailSubscription = () => {
  const [email, setEmail] = useState("");
  const [submissionMsg, setSubmissionMsg] = useState("");
  const [msgStyle, setMsgStyle] = useState("noSubmissionMsg");

  const handleSubmit = (e) => {
    e.preventDefault();

    let pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!pattern.test(email)) {
      setSubmissionMsg(
        "Above is an invalid email address. <br />Please correct it."
      );
      setMsgStyle(
        "phone-size:errorSubscriptionMsg-ps lg:errorSubscriptionMsg-lg"
      );
    } else {
      //setSubmissionMsg("succede!");
      //setMsgStyle('successSubscriptionMsg');

      addToMailchimp(email)
        .then((data) => {
          setSubmissionMsg("Thank you for subscribing to our newsletter!");
          setMsgStyle(
            "phone-size:successSubscriptionMsg-ps lg:successSubscriptionMsg-lg"
          );
          setEmail("");
        })
        .catch((error) => {
          setSubmissionMsg(
            "Sorry for the inconvenience. <br />;Please contact site admin."
          );
          setMsgStyle(
            "phone-size:errorSubscriptionMsg-ps lg:errorSubscriptionMsg-lg"
          );
          setEmail("");
        });
    }
  };

  const handleEmailChange = (event) => {
    setSubmissionMsg("");
    setEmail(event.currentTarget.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 ">
        <input
          placeholder="Email address"
          name="email"
          type="text"
          value={email}
          onChange={handleEmailChange}
          className="rounded h-10 bg-black border border-secondary-light pl-3 text-blue-pale flex-grow outline-none"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-primary-main text-white -ml-1 rounded-r"
        >
          Subscribe
        </button>
      </div>
      <br />
      <span
        className={msgStyle}
        dangerouslySetInnerHTML={{ __html: submissionMsg }}
      />
    </form>
  );
};

export default EmailSubscription;
